import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
// import ReactGA from "react-ga4";
import "font-awesome/css/font-awesome.min.css";
import barsSVG from "./icons/bars.svg";
import bikeSVG from "./icons/bike.svg";
import eatSVG from "./icons/eat.svg";
import galleriesSVG from "./icons/galleries.svg";
import oeSVG from "./icons/oe.svg";
import parkingSVG from "./icons/parking.svg";
import psSVG from "./icons/ps.svg";
import roSVG from "./icons/ro.svg";
import shopsSVG from "./icons/shops.svg";
import tveSVG from "./icons/tve.svg";

import bars from "./buttons/bars.png";
import barsOff from "./buttons/bars_off.png";
import bo from "./buttons/bo.png";
import boOff from "./buttons/bo_off.png";
import eat from "./buttons/eat.png";
import eatOff from "./buttons/eat_off.png";
import galleries from "./buttons/g.png";
import galleriesOff from "./buttons/g_off.png";
import oe from "./buttons/oe.png";
import oeOff from "./buttons/oe_off.png";
import parking from "./buttons/parking.png";
import parkingOff from "./buttons/parking_off.png";
import psf from "./buttons/psf.png";
import psfOff from "./buttons/psf_off.png";
import rail from "./buttons/rail.png";
import railOff from "./buttons/rail_off.png";
import ro from "./buttons/ro.png";
import roOff from "./buttons/ro_off.png";
import shops from "./buttons/shops.png";
import shopsOff from "./buttons/shops_off.png";
import ss from "./buttons/ss.png";
import ssOff from "./buttons/ss_off.png";
import tve from "./buttons/tve.png";
import tveOff from "./buttons/tve_off.png";

// const TRACKING_ID = "UA-38414853-3";
// const TRACKING_ID = "G-C6GTPY3N67"; // Replace with your tracking ID
// ReactGA.initialize(TRACKING_ID);

const blueLocationMarkerIcon = {
  url:
    "data:image/svg+xml;utf-8," +
    encodeURIComponent(`
    <svg width="24" height="34" xmlns="http://www.w3.org/2000/svg">
      <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 12,32 12,32 C12,32 22,17.52 22,12 C22,6.48 17.52,2 12,2 z" fill="#007BFF" stroke="black" stroke-width="1"/>
      <circle cx="12" cy="12" r="5" fill="white" stroke="black" stroke-width="1"/>
    </svg>
  `),
  // scaledSize: new window.google.maps.Size(24, 34),
};

// const redParkingSignMarkerIcon = {
//   url:
//     "data:image/svg+xml;utf-8," +
//     encodeURIComponent(`
//     <svg width="24" height="34" xmlns="http://www.w3.org/2000/svg">
//       <rect x="6" y="2" width="12" height="12" fill="white" stroke="black" stroke-width="1"/>
//       <text x="12" y="11" font-size="10" text-anchor="middle" fill="#FF0000" font-family="Arial">P</text>
//       <rect x="11" y="14" width="2" height="20" fill="black"/>
//     </svg>
//   `),
//   scaledSize: new window.google.maps.Size(24, 34),
// };

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const center = {
  lat: 39.10911,
  lng: -84.514932,
};

const mapStyles = {
  default: null,
  hide: [
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "landscape.man_made",
      elementType: "all",
      stylers: [
        { visibility: "on" },
        { hue: "#6200ff" },
        { saturation: "-100" },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "all",
      stylers: [{ visibility: "on" }, { hue: "#00ff41" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }, { color: "#d9d9d9" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text",
      stylers: [{ color: "#000000" }],
    },
    {
      featureType: "road.arterial",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "transit",
      elementType: "labels.text",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "transit.station.bus",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit.station.rail",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [{ visibility: "simplified" }, { invert_lightness: true }],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [{ visibility: "simplified" }],
    },
  ],
};

const buttonContainerStyles = {
  position: "fixed",
  left: "50%",
  bottom: "10px",
  transform: "translateX(-50%)", // Centers the button container horizontally
  display: "flex",
  flexWrap: "wrap", // Allows buttons to wrap on smaller screens
  justifyContent: "center",
  alignItems: "center",
  gap: "10px", // Spacing between buttons
};

const buttonStyles = {
  minWidth: "50px", // Minimum width for smaller screens
  height: "50px",
  padding: "10px",
  margin: "5px",
  cursor: "pointer",
  flex: "1 1 auto", // Allows the buttons to grow and shrink
};

const toggleButtonStyles = (isActive, backgroundColor) => ({
  width: "100px", // Adjust this value as needed
  height: "50px", // Set a fixed height
  background: backgroundColor,
  filter: isActive ? "brightness(1)" : "brightness(0.75)",
  color: "white",
  border: "none",
  padding: "10px",
  margin: "5px",
  cursor: "pointer",
  textAlign: "center", // Ensures text is centered within each button
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const MemoizedMarker = React.memo(({ position, ...props }) => (
  <Marker position={position} {...props} />
));

const createMarkerIcon = (type) => {
  const iconSVG = `
  <svg width="24" height="34" xmlns="http://www.w3.org/2000/svg">
  <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 12,32 12,32 C12,32 22,17.52 22,12 C22,6.48 17.52,2 12,2 z" fill="grey" stroke="black" stroke-width="1"/>
  <circle cx="12" cy="12" r="5" fill="white" stroke="black" stroke-width="1"/>
</svg>
  `;

  switch (type) {
    case "bars":
      return { url: barsSVG };
    case "bike":
      return { url: bikeSVG };
    case "eat":
      return { url: eatSVG };
    case "galleries":
      return { url: galleriesSVG };
    case "oe":
      return { url: oeSVG };
    case "parking":
      return { url: parkingSVG };
    case "ps":
      return { url: psSVG };
    case "ps":
      return { url: psSVG };
    case "ro":
      return { url: roSVG };
    case "shops":
      return { url: shopsSVG };
    case "tve":
      return { url: tveSVG };
    default:
      return {
        url: `data:image/svg+xml;utf-8,${encodeURIComponent(iconSVG)}`,
        scaledSize: new window.google.maps.Size(24, 34),
      };
  }
  return {
    // url: `data:image/svg+xml;utf-8,${encodeURIComponent(iconSVG)}`,
    url: barsSVG,
    // scaledSize: new window.google.maps.Size(24, 34),
  };
};

function MapComponent() {
  // ReactGA.send({
  //   hitType: "pageview",
  //   page: window.location.pathname + window.location.search,
  //   title: "Visit OTR Map",
  // });

  const mapRef = useRef(null);

  const mapOptions = {
    styles: mapStyles.hide,
    minZoom: 9,
    maxZoom: 19,
    restriction: {
      latLngBounds: {
        north: 39.13,
        south: 39.09,
        east: -84.49,
        west: -84.54,
      },
      strictBounds: true,
    },
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      map.setOptions({
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
      });
    }
  }, [mapRef.current]);

  const [userLocation, setUserLocation] = useState(null);
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const [dataCache, setDataCache] = useState({});
  const [activeGroups, setActiveGroups] = useState({
    parking: {
      active: false,
      chamberid: 47,
      name: "Parking",
      description: "Parking in OTR",
      backgroundcolor: "#3498db",
      color: "#ffffff",
    },
    // online: {
    //   active: false,
    //   chamberid: 48,
    //   name: "Online shopping",
    //   description: "Online shopping in OTR",
    //   backgroundcolor: "#9b59b6",
    //   color: "#ffffff",
    // },
    shops: {
      active: false,
      chamberid: 41,
      name: "Shop",
      description: "Shop in OTR",
      backgroundcolor: "#e74c3c",
      color: "#ffffff",
    },
    eat: {
      active: false,
      chamberid: 42,
      name: "Eat & Drink",
      description: "Eat & Drink in OTR",
      backgroundcolor: "#2ecc71",
      color: "#ffffff",
    },
    bars: {
      active: false,
      chamberid: 43,
      name: "Bars & Pubs",
      description: "Bars & Pubs in OTR",
      backgroundcolor: "#f39c12",
      color: "#ffffff",
    },
    tve: {
      active: false,
      chamberid: 37,
      name: "Theater",
      description: "Theater in OTR",
      backgroundcolor: "#1abc9c",
      color: "#ffffff",
    },
    // blackowned: {
    //   active: false,
    //   chamberid: 51,
    //   name: "Black Owned Business",
    //   description: "Black Owned Business in OTR",
    //   backgroundcolor: "#34495e",
    //   color: "#ffffff",
    // },
    galleries: {
      active: false,
      chamberid: 40,
      name: "Gallery",
      description: "Gallery in OTR",
      backgroundcolor: "#16a085",
      color: "#ffffff",
    },
    ro: {
      active: false,
      chamberid: 39,
      name: "Religious",
      description: "Religious in OTR",
      backgroundcolor: "#27ae60",
      color: "#ffffff",
    },
    oe: {
      active: false,
      chamberid: 38,
      name: "Offices",
      description: "Offices in OTR",
      backgroundcolor: "#2980b9",
      color: "#ffffff",
    },
  });

  const images = {
    bars,
    barsOff,
    bo,
    boOff,
    eat,
    eatOff,
    galleries,
    galleriesOff,
    oe,
    oeOff,
    parking,
    parkingOff,
    psf,
    psfOff,
    rail,
    railOff,
    ro,
    roOff,
    shops,
    shopsOff,
    ss,
    ssOff,
    tve,
    tveOff,
  };

  const sidebarStyles = {
    position: "absolute",
    bottom: "5px",
    left: "5px",
    zIndex: 1000,
    background: "#fff",
    padding: "5px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    border: "1px solid black",
    width: "auto", // Ensures it grows with the content
  };

  const [selectedPlace, setSelectedPlace] = useState(null);

  const fetchData = async (group) => {
    // group = activeGroups[group].chamberid;
    if (!dataCache[group]) {
      try {
        const response = await axios.get(
          `https://3zbxx4dm4j.execute-api.us-east-2.amazonaws.com/default/otrccGetOrganizations?type=organizations&group=${group}`
        );
        // console.log(group);
        // console.log(response.data);
        setDataCache((prevCache) => ({
          ...prevCache,
          [group]: response.data
            .filter((loc) => loc.attributes.OrgId !== "")
            .filter(
              (loc) => loc.attributes.OrgId == activeGroups[group].chamberid
            ),
        }));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  const handleMarkerClick = (place) => {
    setSelectedPlace(place);
    // ReactGA.event({
    //   category: "Map",
    //   action: "Marker Clicked",
    //   label: `Marker ID: ${place.attributes.MemId}:${place.attributes.DisplayName}`,
    // });
  };

  const toggleGroup = (group) => {
    setActiveGroups((prevGroups) => {
      const updatedGroups = {
        ...prevGroups,
        [group]: {
          ...prevGroups[group],
          active: !prevGroups[group].active,
        },
      };
      if (updatedGroups[group].active) {
        fetchData(group);
      }
      return updatedGroups;
    });
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAF0pVwRqx_wSImGwi80X7chUgzKdq1Q_E">
      <div style={sidebarStyles}>
        {Object.keys(activeGroups).map((group) => {
          // Choose the image based on the group's active status
          const imageKey = activeGroups[group].active ? group : `${group}Off`;

          return (
            <img
              key={activeGroups[group].chamberid}
              onClick={() => toggleGroup(group)}
              src={images[imageKey]}
              alt={`${group} toggle`}
              style={{
                width: 90,
                height: 45,
                marginLeft: 5,
                marginBottom: 5,
                cursor: "pointer",
              }}
            />
          );
        })}
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={mapOptions}
        onLoad={(map) => {
          mapRef.current = map;
          const mapContainer = mapRef.current.getDiv();
          mapContainer.addEventListener("touchmove", handleTouchMove, {
            passive: false,
          });
        }}
        onUnmount={() => {
          const mapContainer = mapRef.current.getDiv();
          mapContainer.removeEventListener("touchmove", handleTouchMove);
        }}
      >
        {Object.keys(activeGroups).map(
          (group) =>
            activeGroups[group].active &&
            dataCache[group] &&
            dataCache[group]
              .filter(
                (loc) => loc.attributes.Latitude && loc.attributes.Longitude
              )
              .map((loc, index) => (
                <MemoizedMarker
                  key={index}
                  position={{
                    lat: loc.attributes.Latitude,
                    lng: loc.attributes.Longitude,
                  }}
                  icon={createMarkerIcon(
                    group //,
                    // activeGroups[group].backgroundcolor,
                    // activeGroups[group].color
                  )}
                  onClick={() => handleMarkerClick(loc)}
                />
              ))
        )}

        {selectedPlace && (
          <InfoWindow
            position={{
              lat: selectedPlace.attributes.Latitude,
              lng: selectedPlace.attributes.Longitude,
            }}
            onCloseClick={() => setSelectedPlace(null)}
          >
            <div>
              <a
                href={
                  "https://www.otrchamber.com/organization.html?id=" +
                  selectedPlace.attributes.MemId
                }
                target="_blank"
              >
                <h1>{selectedPlace.attributes.DisplayName}</h1>
              </a>
              <a
                href={
                  "http://maps.google.com/maps?q=&layer=c&cbll=" +
                  selectedPlace.attributes.Latitude +
                  "," +
                  selectedPlace.attributes.Longitude +
                  "&cbp=11,0,0,0,0"
                }
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="3.2" />
                  <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </a>
              <br />
              <p>{selectedPlace.attributes.Description}</p>
              <address>
                {selectedPlace.attributes.Line1 && (
                  <span>
                    {selectedPlace.attributes.Line1}
                    <br />
                  </span>
                )}
                {selectedPlace.attributes.Line2 && (
                  <span>
                    {selectedPlace.attributes.Line2}
                    <br />
                  </span>
                )}
                {selectedPlace.attributes.Line3 && (
                  <span>
                    {selectedPlace.attributes.Line3}
                    <br />
                  </span>
                )}
                {selectedPlace.attributes.Line4 && (
                  <span>
                    {selectedPlace.attributes.Line4}
                    <br />
                  </span>
                )}
                {selectedPlace.attributes.City && (
                  <span>{selectedPlace.attributes.City}</span>
                )}
                {selectedPlace.attributes.Region && (
                  <span>, {selectedPlace.attributes.Region}</span>
                )}
                {selectedPlace.attributes.PostalCode && (
                  <span> {selectedPlace.attributes.PostalCode}</span>
                )}
              </address>
              <br />
              <a href={selectedPlace.attributes.Website} target="_blank">
                {selectedPlace.attributes.Website}
              </a>
            </div>
          </InfoWindow>
        )}
        {userLocation && (
          <Marker position={userLocation} label="You are here" />
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default MapComponent;
